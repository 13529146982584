<template>
    <v-container>
		<div>
			<s-crud
				:config="config"
				add
                @addEvent="AddEventClick()"
				remove
                @removed="remove()"
				search-input
				title="Facturas XML"
                ref="InvoicesXMLCrud"
				@save="save()"
				height="auto"
                @doubleClick="dobleClick"
			>

            <template scope="props">

                <v-row justify="center">
                    <v-col cols="12" md="6" class="d-flex align-center">
                        <v-row class="w-100" align="center">
                            <v-col lg="8" md="8" sm="8">
                                <v-file-input
                                    :disabled="!isEditable"
                                    label="Cargar XML"
                                    v-model="Attach"
                                    hide-details
                                    accept=".xml"
                                />
                            </v-col>
                            <v-col lg="4" md="4" sm="4">
                                <v-btn color="success" x-small class="d-flex justify-center align-center" :disabled="!isFileSelected()"  @click="UploadFile">
                                    <v-icon>mdi-upload</v-icon> cargar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <div class="col-12 mt-2" v-if="invoiceData && Object.keys(invoiceData).length > 0">
                    <v-row justify="center">
                        <div class="col-md-8">
                            <v-col cols="12" md="12">
                                <v-row justify="center" style="margin:auto">
                                        <v-col
                                            cols="12"
                                            md="12"
                                            style="margin-top: -15px;"
                                        >
                                            <div class="text-h4 mb-2 mt-2 text-center font-weight-bold">{{ invoiceData.issuer_company_name }}</div>
                                            <p class="mb-1 text-center"> {{ invoiceData.issuer_address }}</p>
                                        </v-col>
                                    </v-row>
                            </v-col>
                        </div>

                        <div class="col-md-4">
                            <v-col cols="12" md="12">
                                <v-card outlined>
                                    <v-row justify="center" style="margin:auto">
                                        <v-col
                                            cols="12"
                                            md="12"
                                            style="margin-top: -15px;"
                                        >
                                        <div class="text-h6 mb-2 mt-2 text-center font-weight-bold">FACTURA ELECTRÓNICA</div>
                                        </v-col>
                                        <v-col cols="12" md="12" style="margin-top: -15px;">
                                            <div class="text-h6 mb-2 text-center">RUC: {{ invoiceData.issuer_ruc }}</div>
                                            <div class="text-h5 font-weight-bold text-center">{{ invoiceData.invoice_number }}</div>
                                        </v-col>
                                        
                                    </v-row>
                                </v-card>
                            </v-col>
                        </div>

                        <div class="col-md-7">
                            <v-col cols="12" md="12">
                                <v-row justify="center">
                                    <v-col
                                        cols="8"
                                        md="8"
                                        style="margin-top: -15px;"
                                    >
                                        <s-supplier :readonly="!isEditable" label="Proveedor" v-model="Supplier" />
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        md="4"
                                        style="margin-top: -15px;"
                                    >
                                        <s-contact-supplier
                                            :disabled="!isEditable"
                                            label="Contacto"
                                            :SupID="SupID"
                                            v-model="invoiceData.ConID"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -15px;"
                                    >
                                    <p class="mb-5"><strong>DATOS DEL CLIENTE :</strong></p>
                                        <p><strong>Razón Social:</strong> {{ invoiceData.customer_company_name }}</p>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -15px;"
                                    >
                                        <p><strong>RUC:</strong> {{ invoiceData.customer_ruc }}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>

                        <div class="col-md-4">
                            <v-col cols="12" md="12">
                                <v-row justify="center">
                                    <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -15px;"
                                    >
                                        <p><strong>Fecha de Emisión:</strong> {{ invoiceData.issue_date }}</p>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -15px;"
                                    >
                                        <p><strong>Fecha de Vencimiento:</strong> {{ invoiceData.due_date }}</p>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -15px;"
                                    >
                                        <p><strong>Moneda:</strong> {{ invoiceData.currency }}</p>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -15px;"
                                    >
                                        <p><strong>Metodo de Pago:</strong> {{ invoiceData.payment_method }}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-row>

                    <v-row justify="center">
                        <div class="col-md-12">
                            <v-col>
                                <v-card>
                                    <v-col cols="12" md="12">
                                        <v-data-table
                                            dense
                                            fixed-header
                                            :items="itemsSearch"
                                            :headers="headersSearch"
                                            :items-per-page="5"
                                        >
                                        </v-data-table>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </div>
                    </v-row>
                    
                    <v-row justify="center">
                        <div class="col-md-12">
                            <v-col cols="12" md="12">
                                <v-row justify="center">
                                    <v-col cols="12" md="7">
                                        <v-card outlined class="pa-4">
                                            <div class="text-subtitle-1"><strong>SON:</strong> {{ invoiceData.note }}</div>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <v-card outlined>
                                            <v-list dense>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="text-right">
                                                            SubTotal: {{ invoiceData.totals.partial_total }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="text-right">
                                                            IGV: {{ invoiceData.totals.igv }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-divider></v-divider>

                                                <v-list-item class="font-weight-bold">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="text-right text-h6">
                                                            Total: {{ invoiceData.totals.total }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-row>
                </div>
            </template>

            </s-crud>

            <v-dialog persistent v-model="dialogGroupInvoice" max-width="840px">
                <v-card>
                    <v-card-title>
                        <s-toolbar
                            label="Detalle de Factura"
                            dark
                            color="#8E8F91"
                            close
                            @close="closeDialogGroupInvoice()"
                        ></s-toolbar>
                    </v-card-title>

                    <v-card-text>
                        <div class="col-12 mt-2" v-if="invoiceData && Object.keys(invoiceData).length > 0">
                            <v-row justify="center">
                                <div class="col-md-8">
                                    <v-col cols="12" md="12">
                                        <v-row justify="center" style="margin:auto">
                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                    style="margin-top: -15px;"
                                                >
                                                    <div class="text-h4 mb-2 mt-2 text-center font-weight-bold">{{ invoiceData.issuer_company_name }}</div>
                                                    <p class="mb-1 text-center"> {{ invoiceData.issuer_address }}</p>
                                                </v-col>
                                            </v-row>
                                    </v-col>
                                </div>

                                <div class="col-md-4">
                                    <v-col cols="12" md="12">
                                        <v-card outlined>
                                            <v-row justify="center" style="margin:auto">
                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                    style="margin-top: -15px;"
                                                >
                                                <div class="text-h6 mb-2 mt-2 text-center font-weight-bold">FACTURA ELECTRÓNICA</div>
                                                </v-col>
                                                <v-col cols="12" md="12" style="margin-top: -15px;">
                                                    <div class="text-h6 mb-2 text-center">RUC: {{ invoiceData.issuer_ruc }}</div>
                                                    <div class="text-h5 font-weight-bold text-center">{{ invoiceData.invoice_number }}</div>
                                                </v-col>
                                                
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </div>

                                <div class="col-md-7">
                                    <v-col cols="12" md="12">
                                        <v-row justify="center">
                                            
                                            <v-col
                                                cols="12"
                                                md="12"
                                                style="margin-top: -15px;"
                                            >
                                            <p class="mb-5"><strong><u>DATOS DEL CLIENTE</u></strong></p>
                                                <p><strong>Razón Social:</strong> {{ invoiceData.customer_company_name }}</p>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                style="margin-top: -15px;"
                                            >
                                                <p><strong>RUC:</strong> {{ invoiceData.customer_ruc }}</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </div>

                                <div class="col-md-4">
                                    <v-col cols="12" md="12">
                                        <v-row justify="center">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                style="margin-top: -15px;"
                                            >
                                                <p><strong>Fecha de Emisión:</strong> {{ invoiceData.issue_date }}</p>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                style="margin-top: -15px;"
                                            >
                                                <p><strong>Fecha de Vencimiento:</strong> {{ invoiceData.due_date }}</p>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                style="margin-top: -15px;"
                                            >
                                                <p><strong>Moneda:</strong> {{ invoiceData.currency }}</p>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                style="margin-top: -15px;"
                                            >
                                                <p><strong>Metodo de Pago:</strong> {{ invoiceData.payment_method }}</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </div>
                            </v-row>

                            <v-row justify="center">
                                <div class="col-md-12">
                                    <v-col>
                                        <v-card>
                                            <v-col cols="12" md="12">
                                                <v-data-table
                                                    dense
                                                    fixed-header
                                                    :items="itemsSearch"
                                                    :headers="headersSearch"
                                                    :items-per-page="5"
                                                >
                                                </v-data-table>
                                            </v-col>
                                        </v-card>
                                    </v-col>
                                </div>
                            </v-row>
                        
                            <v-row justify="center">
                                <div class="col-md-12">
                                    <v-col cols="12" md="12">
                                        <v-row justify="center">
                                            <v-col cols="12" md="7">
                                                <v-card outlined class="pa-4">
                                                    <div class="text-subtitle-1"><strong>SON:</strong> {{ invoiceData.note }}</div>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" md="5">
                                                <v-card outlined>
                                                    <v-list dense>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="text-right">
                                                                    SubTotal: {{ invoiceData.totals.partial_total }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>

                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="text-right">
                                                                    IGV: {{ invoiceData.totals.igv }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>

                                                        <v-divider></v-divider>

                                                        <v-list-item class="font-weight-bold">
                                                            <v-list-item-content>
                                                                <v-list-item-title class="text-right text-h6">
                                                                    Total: {{ invoiceData.totals.total }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </div>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>

import _sInvoiceXMLService from "../../../services/Accounting/InvoiceXMLService";
import SSupplier from "../../../components/Utils/Logistics/SSupplier.vue";
import SContactSupplier from "../../../components/Utils/Logistics/SContactSupplier.vue";

export default {
  name: "",
  components: {
    SSupplier,
    SContactSupplier
  },
  props: {},
  data: () => ({
    filter: {},
    Attach: null,
    dialogGroupInvoice: false,
    invoiceData: {},
    Supplier: null,
    ConID: null,
    SupID: "",
    itemsSearch: [],
    InvoiceXML: {  },
    config: {
      model: {
        VxmID: "ID",
        VxmIssueDate: "date",
        VxmDueDate: "date"
      },
      service: _sInvoiceXMLService,
      headers: [
        { text: "ID", value: "VxmID", sortable: false, width: 10 },
        { text: "Proveedor", value: "SupName", sortable: false },
        { text: "Ruc", value: "VxmRuc", sortable: false },
        { text: "Fecha E.", value: "VxmIssueDate", sortable: false },
        { text: "Serie", value: "VxmSerie", sortable: false },
        { text: "Moneda", value: "VxmCurrency", sortable: false },
        { text: "Total", value: "VxmTotal", sortable: false },
      ],
    },
    headersSearch: [
        { text: "Cantidad", value: "quantity", width: 20 },
        { text: "Descripcion", value: "description", width: 250 },
        { text: "Impuesto", value: "tax", width: 50 },
        { text: "Tipo Afectación", value: "type_affectation", width: 50 },
        { text: "Valor Unitario", value: "unit_price", width: 50 },
        { text: "Total", value: "total", width: 50 },
    ],
  }),
  methods: {
    save() {
        console.log(this.Supplier);
        if (this.Supplier == null) {
            this.$fun.alert("Falta seleccionar el proveedor", "warning");
            return;
        }

        const normalizedSupID = this.Supplier.SupID.replace(/\D/g, '');
        const normalizedIssuerRuc = this.invoiceData.issuer_ruc.replace(/\D/g, '');
        const normalizedSupName = this.Supplier.SupName.replace(/\./g, '').toUpperCase(); 
        const normalizedIssuerName = this.invoiceData.issuer_company_name.replace(/\./g, '').toUpperCase();

        if (normalizedSupID != normalizedIssuerRuc && normalizedSupName != normalizedIssuerName) {
            this.$fun.alert("El proveedor seleccionado no coincide", "warning");
            return;
        }

        this.InvoiceXML.SupCode = this.Supplier.SupID;
        this.InvoiceXML.SupName = this.Supplier.SupName;
        this.InvoiceXML.VxmRuc = this.invoiceData.issuer_ruc;
        this.InvoiceXML.VxmSerie = this.invoiceData.invoice_number;
        this.InvoiceXML.VxmIssueDate = this.invoiceData.issue_date;
        this.InvoiceXML.VxmDueDate = this.invoiceData.due_date;
        this.InvoiceXML.VxmPaymentMethod = this.invoiceData.payment_method;
        this.InvoiceXML.VxmCompanyName = this.invoiceData.customer_company_name;
        this.InvoiceXML.VxmRucCompany = this.invoiceData.customer_ruc;
        this.InvoiceXML.VxmCurrency = this.invoiceData.currency;
        this.InvoiceXML.VxmLegend = this.invoiceData.note;
        this.InvoiceXML.VxmSubTotal = this.invoiceData.totals.partial_total;
        this.InvoiceXML.VxmIgv = this.invoiceData.totals.igv;
        this.InvoiceXML.VxmTotal = this.invoiceData.totals.total;
        this.InvoiceXML.UsrCreateID = this.$fun.getUserID();

        for (let i = 0; i < this.invoiceData.details.length; i++) {
            this.invoiceData.details[i].DxmQuantity = this.invoiceData.details[i].quantity;
            this.invoiceData.details[i].DxmDescription = this.invoiceData.details[i].description;
            this.invoiceData.details[i].DxmTax = this.invoiceData.details[i].tax;
            this.invoiceData.details[i].DxmIgvPercentage = this.invoiceData.details[i].igv_percentage;
            this.invoiceData.details[i].DxmTypeAffectation = this.invoiceData.details[i].type_affectation;
            this.invoiceData.details[i].DxmUnitPrice = this.invoiceData.details[i].unit_price;
            this.invoiceData.details[i].DxmTotal = this.invoiceData.details[i].total;
            this.invoiceData.details[i].UsrCreateID = this.$fun.getUserID();
        }

        this.InvoiceXML.Detail = this.invoiceData.details;

        this.$fun.alert("Seguro de guardar?", "question").then((r) => {
            if (r.value) {

                _sInvoiceXMLService
                    .save(this.InvoiceXML, this.$fun.getUserID())
                    .then((r) => {
                        console.log(r.data.length)
                        if (r.data.length > 1) {

                        } else {
                            this.$fun.alert("Guardado Correctamente", "success");
                            this.$refs.InvoicesXMLCrud.refresh();
                        }
                    });
            }
        });
    },
    remove(){
        console.log("afeaf")
    },
    dobleClick(item) {

      if (item.VxmID != null) {
        _sInvoiceXMLService.detail({ VxmID: item.VxmID }, this.$fun.getUserID()).then((r) => {
            
            r.data.details = r.data;
            if (!this.invoiceData) {
                this.invoiceData = {};
            }

            this.invoiceData = {
                invoice_number: item.VxmSerie ,
                customer_company_name: item.VxmCompanyName, 
                customer_ruc: item.VxmRucCompany, 
                customer_address: '', 
                issuer_ruc: item.VxmRuc,
                issuer_company_name: item.SupName,
                issuer_address: '',
                issue_date: item.VxmIssueDate,
                due_date: item.VxmDueDate,
                note: item.VxmLegend,
                currency: item.VxmCurrency,
                payment_method: item.VxmPaymentMethod,
                totals: {
                    partial_total: item.VxmSubTotal,
                    igv: item.VxmIgv,
                    total: item.VxmTotal,
                },
                details: Array.isArray(r.data.details) ? r.data.details.map(detail => {
                    return {
                        description: detail.DxmDescription,
                        quantity: detail.DxmQuantity,
                        tax : detail.DxmTax,
                        unit_price: detail.DxmUnitPrice,
                        igv_percentage: detail.DxmIgvPercentage,
                        type_affectation: detail.DxmTypeAffectation,
                        total: detail.DxmTotal
                    };
                }) : [],
            };

            this.itemsSearch = this.invoiceData.details;
            this.openDialogGroupInvoice();
        });
      }
    },
    openDialogGroupInvoice(){
        this.dialogGroupInvoice = true;
    },
    closeDialogGroupInvoice(){
        this.dialogGroupInvoice = false;
        this.invoiceData = [];
    },
    AddEventClick(){
      this.invoiceData = [];
      this.Supplier = null;
    },
    isEditable() {
        return (
            (this.InvoiceXML.OrdStatus == 3 &&
            this.$fun.getUserID() == this.InvoiceXML.UsrCreateID) ||
            this.InvoiceXML.OrdID == null
        );
    },
    isFileSelected() {
        return this.Attach !== null && this.Attach !== ''; // Verifica si Attach tiene un archivo
    },
    UploadFile() {
        const file = this.Attach; // Archivo cargado

        if (!file) {
            this.$fun.alert("No se ha seleccionado un archivo XML", "warning");
            return;
        }

        const reader = new FileReader();

        reader.onload = async (e) => {
            const xmlText = e.target.result;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlText, "application/xml");

            // Verificar si hay errores de parsing
            if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
                this.$fun.alert("El archivo no es un XML válido", "warning");
                return;
            }

            const nsResolver = (prefix) => {
                const namespaces = {
                    'cac': 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
                    'cbc': 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
                    'def': 'urn:oasis:names:specification:ubl:schema:xsd:Invoice-2'
                };
                return namespaces[prefix] || null;
            };

            // Validar si es una factura UBL
            const isInvoice = xmlDoc.evaluate(
                "boolean(//*[local-name()='Invoice'])",
                xmlDoc,
                nsResolver,
                XPathResult.BOOLEAN_TYPE,
                null
            ).booleanValue;

            if (!isInvoice) {
                this.$fun.alert("El archivo XML no es una factura válida", "warning");
                return;
            }

            // Extraer datos de la factura
            this.invoiceData = {
                invoice_number: xmlDoc.evaluate(
                    "//*[local-name()='Invoice']/*[local-name()='ID'][not(parent::*[local-name()='OrderReference'])][not(parent::*[local-name()='Signature'])]/text()",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                customer_company_name: xmlDoc.evaluate(
                    "//cac:AccountingCustomerParty/cac:Party/cac:PartyLegalEntity/cbc:RegistrationName/text()",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                customer_ruc: xmlDoc.evaluate(
                    "//cac:AccountingCustomerParty/cac:Party/cac:PartyIdentification/cbc:ID",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                customer_address: xmlDoc.evaluate(
                    "//cac:AccountingCustomerParty/cac:Party/cac:PartyLegalEntity/cac:RegistrationAddress/cac:AddressLine/cbc:Line/text()",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                issuer_ruc: xmlDoc.evaluate(
                    "//cac:AccountingSupplierParty/cac:Party/cac:PartyIdentification/cbc:ID",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                issuer_company_name: xmlDoc.evaluate(
                    "//cac:AccountingSupplierParty/cac:Party/cac:PartyLegalEntity/cbc:RegistrationName/text()",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                issuer_address: xmlDoc.evaluate(
                    "//cac:AccountingSupplierParty/cac:Party/cac:PartyLegalEntity/cac:RegistrationAddress/cac:AddressLine/cbc:Line/text()",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                issue_date: xmlDoc.evaluate(
                    "//cbc:IssueDate",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                due_date: xmlDoc.evaluate(
                    "//cbc:DueDate",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                note: xmlDoc.evaluate(
                    "//cbc:Note[@languageLocaleID='1000']/text()",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                currency: xmlDoc.evaluate(
                    "//cbc:DocumentCurrencyCode",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue,
                payment_method: xmlDoc.evaluate(
                    "//cac:PaymentTerms/cbc:PaymentMeansID",
                    xmlDoc,
                    nsResolver,
                    XPathResult.STRING_TYPE,
                    null
                ).stringValue || "N/A",
                details: (() => {
                    const details = [];
                    const invoiceLines = xmlDoc.evaluate(
                        "//cac:InvoiceLine",
                        xmlDoc,
                        nsResolver,
                        XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
                        null
                    );

                    for (let i = 0; i < invoiceLines.snapshotLength; i++) {
                        const line = invoiceLines.snapshotItem(i);
                        details.push({
                            quantity: xmlDoc.evaluate(
                                "cbc:InvoicedQuantity/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                            description: xmlDoc.evaluate(
                                "cac:Item/cbc:Description/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                            unit_price: xmlDoc.evaluate(
                                "cac:PricingReference/cac:AlternativeConditionPrice/cbc:PriceAmount/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                            tax: xmlDoc.evaluate(
                                "cac:TaxTotal/cbc:TaxAmount/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                            igv_percentage: xmlDoc.evaluate(
                                "cac:TaxTotal/cac:TaxSubtotal/cac:TaxCategory/cbc:Percent/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                            type_affectation: xmlDoc.evaluate(
                                "cac:TaxTotal/cac:TaxSubtotal/cac:TaxCategory/cac:TaxScheme/cbc:Name/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                            total: xmlDoc.evaluate(
                                "cbc:LineExtensionAmount/text()",
                                line,
                                nsResolver,
                                XPathResult.STRING_TYPE,
                                null
                            ).stringValue,
                        });
                    }
                    return details;
                })(),
                totals: {
                    partial_total: xmlDoc.evaluate(
                        "//cac:LegalMonetaryTotal/cbc:LineExtensionAmount",
                        xmlDoc,
                        nsResolver,
                        XPathResult.STRING_TYPE,
                        null
                    ).stringValue,
                    igv: xmlDoc.evaluate(
                        "//cac:TaxTotal/cbc:TaxAmount",
                        xmlDoc,
                        nsResolver,
                        XPathResult.STRING_TYPE,
                        null
                    ).stringValue,
                    total: xmlDoc.evaluate(
                        "//cac:LegalMonetaryTotal/cbc:PayableAmount",
                        xmlDoc,
                        nsResolver,
                        XPathResult.STRING_TYPE,
                        null
                    ).stringValue,
                },
            };

            this.itemsSearch = this.invoiceData.details;

            this.Attach = null;
        };

        reader.readAsText(file, 'ISO-8859-1');
    },

  },
  created() {},
  watch: {
    Supplier() {
      if (this.Supplier != null)
        if (this.Supplier.SupID != null) 
            this.SupID = this.Supplier.SupID;
    },
  },
};
</script>