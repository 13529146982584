import Service from "../Service";

const resource = "CntInvoicesXML/";

export default {
    
    save(xml, requestID) {
        return Service.post(resource + "save", xml, {
            params: { requestID: requestID},
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    detail(inv, requestID) {
        return Service.post(resource + "detailByInvoice", inv, {
            params: { requestID: requestID },
        });
    },
};